.custom_sidebar {
  height: 100%;
  width: 17%;
  float: left;
  background-color: #f7f8fc;
  position: fixed;
  padding: 0rem 0rem;
  padding-bottom: 360px;
  border: 2px solid #f7f8fc;
  border-right: 1px solid rgb(112, 112, 112, 0.18);
  img {
      width: 138px;
      height: 138px;
      margin-left: 94px;
      margin-top: 45px;
  }
  .adminName {
      font: normal normal normal 46px/62px Segoe UI;
      letter-spacing: 0px;
      color: #3D3D3D;
      opacity: 1;
      margin-left: 69px;
      margin-top: 20px;
  }
  .adminDesignation {
      margin-top: 5px;
      margin-left: 115px;
      margin-bottom: 63px;
      font: normal normal normal 16px/21px Segoe UI;
      letter-spacing: 0px;
      color: #B9B9B9;
  }
  .sidebar_parent_container {
    text-align: left;
    font-size: $text-xl;
    font-weight: 600;
    letter-spacing: 0px;
    color: $gray;
    .sidebar_parent {
      height: 4.125rem;
      text-transform: uppercase;
      font-size: $text-lg;
      color: #767676;
      padding: 1rem 0rem;
      display: flex;
      align-items: center;
      padding-left: 2rem;
      svg {
        margin-right: 1rem;
      }
    }
    .parentActive {
      color: $primary-black !important;
      svg {
        path {
          stroke: $primary-black;
        }
        circle {
          stroke: $primary-black;
        }
      }
    }

    .sidebar_child {
      height: 4.125rem;
      padding: 1rem 0rem;
      display: flex;
      align-items: center;
      font: normal normal normal 20px/27px Segoe UI;
      padding-left: 4.35rem;
      cursor: pointer;
      svg {
        margin-right: 1rem;
      }
    }
    // .sidebar_child.active {
    //   svg {
    //     path {
    //       stroke: #1447d4;
    //       fill: #1447d4;
    //     }
    //     g {
    //       path {
    //         stroke: #1447d4;
    //         fill: #1447d4;
    //       }
    //     }
    //     g {
    //       rect {
    //         // stroke: #1447d4;
    //         // fill: #1447d4;
    //       }
    //     }
    //   }
    // }

    .active {
      background-color: #e6eaf8;
      color: #1447d4;
      border-right: 4px solid $primary-blue;
      font: normal normal 600 20px/27px Segoe UI;
    }
  }
}
.custom_Container {
  .custonFontSavePopup{
    z-index: 1;
    text-align: left;
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 0px;
    color: $primary-black;
    padding: 9px 9px;
    border-bottom: 1px solid $separator;
    position: absolute;
    right: 0;
    left: 0;
    background: $extraWhite 0% 0% no-repeat padding-box;
    .ctmFont{
      font: normal normal 600 18px/24px $text-font-family;
      color: $burgundy;}
    .svd{
      font: normal normal normal 18px/24px $text-font-family;
      color: $burgundy;}
    .checkIcon{
      color: $burgundy;
      font-size: medium;
      margin-right: 12px;
    }
    .crossIcon{
      float: right;
      color: $prestigeBlue;
      opacity: 0.75;
      cursor: pointer;
    }
  }
  .customContainerContent-heading {
    // position: relative;
    position: fixed;
    z-index: 12;
    top: 72px;
    width: 100%;
    background: #f7f8fc 0% 0% no-repeat padding-box;
    text-align: left;
    font-size: $text-2xl;
    font-weight: 600;
    letter-spacing: 0px;
    color: $primary-black;
    padding: 0.75rem 3.75rem;
    border-bottom: 1px solid #dde3ec;
    .backArrow {
      height: 16px;
      margin-left: -50px;
      margin-right: 15px;
      &:hover {
        cursor: pointer;
      }
    }
  }
    .customContainerContent {
      width: 83%;
      height: 100%;
      position: relative;
      // left: 17%;
      margin-left: 17%;
      .emptyThemeContainer {
        width: 100%;
        text-align: center;
        margin-top: 220px;
        .emptyThemeIcon {
          width: 141px;
          height: 100px;
        }
        .createThemeText {
          font: normal normal 600 22px/30px Segoe UI;
          letter-spacing: 0px;
          color: #0C2E70;
          text-transform: uppercase;
          opacity: 1;
        }
        .description {
          text-align: center;
          font: normal normal normal 18px/24px Segoe UI;
          letter-spacing: 0px;
          color: #8A8A8A;
          width: 409px;
          margin-left: 595px;
        }
        .createThemeButton {
          width: 165px;
          margin-top: 5px;
          text-transform: capitalize;
          height: 42px;
          background: #4A8409 0% 0% no-repeat padding-box;
          border-radius: 21px;
          font: normal normal 600 18px/24px Segoe UI;
          letter-spacing: 0px;
          color: #FFFFFF;
          /* margin-right: 64px; */
        }
      }
      .customThemeGrid{
        .themeNameContainer{
          margin-left: 61px;
          margin-top: 128px;
          .themeName {
            font: normal normal 600 16px/21px Segoe UI;
            letter-spacing: 0px;
            color: #3D3D3D;
            padding-top: 35px;
          }
          .textBox {
            width: 655px;
            height: 36px;
            margin-top: 10px;
            .MuiOutlinedInput-input {
              padding: 8px;
            }
          }
          .MuiButton-textPrimary {
            color: #1447D4;
            font: normal normal 600 18px/24px Segoe UI;
            letter-spacing: 0px;
            text-transform: none;
        }
        }
        .customTheme {
          margin-left: 3.75rem;
          margin-top: 45px;
            .colorType {
              font: normal normal normal 18px/24px Segoe UI;
              letter-spacing: 0px;
              color: #8A8A8A;
              margin-bottom: 23px;
            }
            .customColor_Container {
              display: flex;
              .grid {
                width: 508px;
                .customColor {
                  width: 60px;
                  height: 60px;
                  border-radius: 100px;
                  // background-color: red;
              }
              .customColor_details {
                margin-top: -85px;
                margin-left: 85px;
                .customColorType{
                  font: normal normal normal 18px/24px Segoe UI;
                  letter-spacing: 0px;
                  color: #2F3543;
                }
                .customColorDescription {
                  margin-top: -15px;
                  font: normal normal normal 12px/16px Segoe UI;
                  letter-spacing: 0px;
                  color: #8A8A8A;
                }
                .colorEditButton {
                  // text-decoration: none;
                  font: normal normal normal 18px/24px Segoe UI;
                  letter-spacing: 0px;
                  color: #1447D4;
                  cursor: pointer;
                  position: absolute;
                }
                .custom-layout {
                  width: 250px;
                  margin-top: -30px;
                  margin-left: 18px;
                  height: 250px;
                  .react-colorful {
                    width: 250px;
                    height: 250px;
                    .react-colorful__saturation {
                      margin: 15px 0;
                      border-radius: 5px;
                      border-bottom: none;
                    }
                    .react-colorful__hue {
                      order: -1;
                    }
                    .react-colorful__hue,
                    .react-colorful__alpha {
                      height: 18px;
                      border-radius: 8px;
                    }
                    .react-colorful__hue-pointer,
                    .react-colorful__alpha-pointer {
                      width: 20px;
                      height: 20px;
                    }
                  }
                  .swatch {
                    width: 28px;
                    height: 28px;
                    border-radius: 100px;
                    border: 3px solid #fff;
                    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
                    cursor: pointer;
                    position: absolute;
                    margin-top: 10px;
                  }
                  span {
                    position: absolute;
                    left: 12%;
                    bottom: 50.5%
                  }
                  .rgbaValueCOntainer {
                    margin-top: -32px;
                    content: "";
                    width: 279px;
                    margin-left: 84px;
                    display: table;
                    clear: both;
                    input.rgbaInput {
                      float: left;
                      border: none;
                      font-size: 18px;
                      border-bottom: solid 0.5px;
                      margin-right: 10px;
                      padding: 3px;
                      padding-left: 5px;
                      width: 10%;
                  }
                  input.rgbaInput:focus  {
                    border: none;
                    border-bottom: solid 0.5px #4ea5f8;
                    outline: none;
                  }
                }
                input.hexInput {
                  margin-top: -11px;
                  width: 100px;
                  margin-left: 10px;
                  border: none;
                  font-size: 18px;
                  border-bottom: solid 0.5px;
                  padding: 5px;
                  padding-left: 5px;
                }
                input.hexInput:focus  {
                  border: none;
                  border-bottom: solid 0.5px #4ea5f8;
                  outline: none;
                }
                input.alphaInput {
                  width: 45px;
                  margin-top: -11px;
                  margin-left: 10px;
                  border: none;
                  font-size: 18px;
                  border-bottom: solid 0.5px;
                  padding: 5px;
                  padding-left: 5px;
                  }
                  input.alphaInput:focus  {
                    border: none;
                    border-bottom: solid 0.5px #4ea5f8;
                    outline: none;
                  }
                }
                input[type=color]{
                  content: "Edit";
                  color: #1447D4;
                  appearance: none;
                  
                  margin: 0;
                  border: 0;
                  padding: 0;
                  width: 0;
                  height: 0;
                  margin: -100px 0px -0px 100px;
                }
                 input[type=color]::before {
                  content: "Edit";
                  cursor: pointer;
                  font-size: 18px;
                  display: block;
                  background: white;
                  background: attr(value color, white);
                  width: 35px;
                  height: 30px;
                  margin: -35px 0px -18px -102px;
                  border: 2px white;
                  z-index: 1;
                  border-radius: 50%;
                 }
              }
              }
          }
        }
        .buttons{
          margin-left: 64%;
          margin-bottom: 39px;
          margin-top: 110px;
          .cancelButton {
            text-transform: capitalize;
            width: 102px;
            height: 42px;
            background: $wildSand 0% 0% no-repeat padding-box;
            border-radius: 21px;
            font: normal normal 600 18px/24px $text-font-family;
            letter-spacing: 0px;
            color: $labelcolor;
            margin-right: 30px;
          }
          .saveButton {
            text-transform: capitalize;
            width: 156px;
            height: 42px;
            background: $primary-green 0% 0% no-repeat padding-box;
            border-radius: 21px;
            font: normal normal 600 18px/24px $text-font-family;
            letter-spacing: 0px;
            color: $primary-white;
            margin-right: 64px;
          }
        }
      }
  

      .defaultFontGrid{
      .cardMain{
        margin-left:  60px;
        display: flex;
        flex-wrap: wrap;
        .frmCtr{
          margin-left: 20px;

          .radioGrp{
          .frmCtrLbl{
            span:first-of-type{
             margin-right: 16px;
             color:#0C2E70;
          
            }
            span{
              font: normal normal 600 20px/27px $text-font-family;
              color: $maire;
            }

          }
          }
        }
        .cardDefaultFont{
          margin-right:32px;
          align-items: center;
          display: flex;
          justify-content: center;
          margin-bottom: 32px;
          width: 250px;
          min-width: 250px;

          .cardContentDefaultFont{

            .Heading{
              margin-top: 36px;
              margin-left: 27px;
              display: flex;
              justify-content: center;
            }
            .fontSizeWeight{
              // font: normal normal normal 18px/24px $text-font-family;
              font: normal normal normal 17px/22px Segoe UI;
              color: $prestigeBlue;
              margin-top: 16px;
              margin-bottom: 8px;
              display: flex;
              justify-content: center;

            }
            .lineHeight{
              font: normal normal normal 18px/24px $text-font-family;
              color:$prestigeBlue;
              margin-bottom: 27px;
              margin-left: 45px;
              display: flex;
              justify-content: center;
            }
          }

        }
      }

      .buttons{
        position: absolute;
        top:765px;
        right:51px;
        .cancelButton {
          text-transform: capitalize;
          width: 102px;
          height: 42px;
          background: $wildSand 0% 0% no-repeat padding-box;
          border-radius: 21px;
          font: normal normal 600 18px/24px $text-font-family;
          letter-spacing: 0px;
          color: $labelcolor;
          margin-right: 30px;
        }
        .saveButton {
          text-transform: capitalize;
          width: 86px;
          height: 42px;
          background: $primary-green 0% 0% no-repeat padding-box;
          border-radius: 21px;
          font: normal normal 600 18px/24px $text-font-family;
          letter-spacing: 0px;
          color: $primary-white;
          margin-right: 64px;
        }
      }

      }


      .customFontGrid{

        .frmCtrEdit{
          margin-left: 48px;
        
        }

        .cardCustomFont{
          margin-right:32px;
          align-items: center;
          display: flex;
          justify-content: center;
          margin-bottom: 32px;
          width: 250px;
          min-width: 250px;

          .cardContentCustomFont{

            .Heading{
              margin-top: 36px;
              margin-left: 27px;
              display: flex;
              justify-content: center;
            }
            .fontSizeWeight{
              font: normal normal normal 17px/22px Segoe UI;
              color: $prestigeBlue;
              margin-top: 16px;
              margin-bottom: 8px;
              display: flex;
              justify-content: center;

            }
            .lineHeight{
              font: normal normal normal 18px/24px $text-font-family;
              color:$prestigeBlue;
              margin-bottom: 27px;
              margin-left: 45px;
              display: flex;
              justify-content: center;
            }
          }

        }

        .frmCtr{
          margin-left: 20px;

          .radioGrp{
          .frmCtrLbl{
            span:first-of-type{
             margin-right: 16px;
             color: #0C2E70;
          
            }
            span{
              font: normal normal 600 20px/27px $text-font-family;
              color: $maire;
            }

          }
          }
        }
        .fontSelectionMain{
          margin: 0px 64px 0px 48px;
          display: flex;
          flex-direction: column;

          .heading{
            display: flex;
            justify-content: space-between;


   .addFontButton {   
     cursor: pointer;
      margin-top: 20px;
      text-transform: capitalize;
      width: 156px;
      height: 42px;
      background: #4a8409 0% 0% no-repeat padding-box;
      border-radius: 21px;
      font: normal normal 600 18px/24px "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
      letter-spacing: 0px;
      color: #fff;
  }
          }

        }
        .fontFamilyText{
          text-align: left;
          font: normal normal normal 18px/24px Segoe UI;
          color: #000000;
          opacity: 1;
          display: flex;
          align-items: center;
          width: 600px;
          border: 0.5px solid #ADADAD;
          border-radius: 4px;
          padding: 6px 15px 6px 35px;
        }

        .noCustomFont {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 212px;

  
          .addFontTxt{
          font: normal normal 600 22px/30px Segoe UI;
          color: #0C2E70;
          text-align: center;
          margin-bottom: 0;
          }
  
          .welcomeTxt{
            font: normal normal normal 18px/24px Segoe UI;
            color: #8A8A8A;
            margin-bottom: 24px;
          }
  
        
  
        }
        .cardMain{

        
          margin-left:  40px;
          display: flex;
          flex-wrap: wrap;

          .fontFamilyDropdown{
             padding: 6px 15px 6px 19px;
             border: 0.5px solid #ADADAD;
             border-radius: 4px;
            .fontFamilyHeading{
              text-align: left;
              font: normal normal normal 18px/24px Segoe UI;
              color: #ADADAD;
              opacity: 1;
              display: flex;
              align-items: center;
              cursor: pointer;
              width: 340px;
          
              svg {
                font-size: 1.8rem;
                margin-top: 0.25rem;
                margin-left:auto;
                cursor: pointer;
              }
            }

            

            .fontFamilyMenuitems{
              max-height: 400px;
              overflow: auto;
              margin-top: 17px;
              min-width: 375px;
              margin-left: -20px;
              width: fit-content;
              font: normal normal normal 20px/27px $text-font-family;
                color: $prestigeBlue;
                position: absolute;
                z-index: 1;
                background: $primary-white 0% 0% no-repeat padding-box;
                box-shadow: 0px 7px 64px #00000012;
                border: 1px solid $greyLightShade;
                border-radius: 6px;
            
                opacity: 1;
                .fontFamilyitem {
                  padding: 0.5rem 32px 0.5rem 32px;
                  white-space: nowrap;
                  cursor: pointer;
                }
                .fontFamilyitem.selected {
                  background: $solitute 0% 0% no-repeat padding-box;
                  text-align: left;
                  letter-spacing: 0px;
                  color: $primary-blue;
                  font-weight: 600;
                  opacity: 1;
                  cursor: pointer;
                }
              .fontFamilySubitem{
                font: normal normal normal 20px/20px $text-font-family;
                color: $gray;
                margin-left: 10px
              }

            
              }

          }
          .frmCtr{
            margin-top: 42px;
  
            .radioGrp{
            .frmCtrLbl{
              span:first-of-type{
               margin-right: 16px;
            
              }
              span{
                font: normal normal 600 20px/27px $text-font-family;
                color: $maire;
              }
  
            }
            }
          }

          .editCard{
            position: relative;
          .cardCreateFont{
            margin-right:32px;
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top: 32px;
            position: relative;
            width: 250px;
  
  .cardContentDefaultFont{
      .editIcon{
          position: absolute;
          color: $blueTitle;
          right: 20px;
          bottom: 24px;
          cursor: pointer;
        }
  
    .Heading{
      margin-top: 36px;
      margin-left: 27px;
      display: flex;
      justify-content: center;
    }
    .fontSizeWeight{
      font: normal normal normal 18px/24px $text-font-family;
      color: $prestigeBlue;
      margin-top: 16px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
  
    }
    .lineHeight{
      font: normal normal normal 18px/24px $text-font-family;
      color:$prestigeBlue;
      margin-bottom: 27px;
      margin-left: 45px;
      display: flex;
      justify-content: center;
    }
  }
  
          }

          .fontPopup{
            z-index: 1;
            top: 32px;
            position: absolute;
            background-color: $ghost_white;
            width: 310px;
            height: 445px;
            padding: 40px 16px 40px 16px;
                box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.161);
               border-radius: 4px;
               .InfoIcon {
                position: absolute;
                top: 10px;
                right: 10px;
                color: #1447D4;
              }
            .PopupFontUpperText{
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              .fontSizeWeight{
                font: normal normal normal 18px/24px $text-font-family;
                color: $prestigeBlue;
                margin-top: 16px;
                margin-bottom: 8px;
                display: flex;
                justify-content: center;
            
              }
              .lineHeight{
                font: normal normal normal 18px/24px $text-font-family;
                color:$prestigeBlue;
                margin-bottom: 27px;
                margin-left: 45px;
                display: flex;
                justify-content: center;
              }
            }
          }
          .letterStyle{
           background-color: $whiteSmokeShade;
           height: 51px;
           margin-top: 16px;
           .letterStyleAlign{
            display: flex;
            justify-content: space-around;
            padding-top: 16px;

            .filled{
              text{
                fill:#2298e6;
              }
            }
           }
          }

         .popupButtons {
            display: flex;
            margin-left: 50%;
            justify-content: space-around;
            margin-top: 55px;
          }
          .horizontalAlign{
            display: flex;
    justify-content: space-between;
    margin-top: 16px;
    .searchBoxLetter {
      display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid $greyLightShade;
    width: 90px;
    background-color: $primary-white;
    border-radius: 6px;
      svg {
        position: relative;
        right: 12px;
        color: $primary-gray;
      }

      .MuiTextField-root {
        .MuiOutlinedInput-root {
          height: 36px;
        }
        height: 36px;
        input {
          text-align: left;
          font: normal normal 400 15px $text-font-family;
          letter-spacing: 0px;
          color: $matterHornShade;
        }
        fieldset {
          height: 36px;
          border-style: none;
        }
      }
    }
          }
        }
        }

        .fontSelectionButtons{
         
          position: fixed;
          top:865px;
          right:51px;
          .cancelButton {
            text-transform: capitalize;
            width: 102px;
            height: 42px;
            background: $wildSand 0% 0% no-repeat padding-box;
            border-radius: 21px;
            font: normal normal 600 18px/24px $text-font-family;
            letter-spacing: 0px;
            color: $labelcolor;
            margin-right: 30px;
          }
          .saveButton {
            text-transform: capitalize;
            width: 156px;
            height: 42px;
            background: $primary-green 0% 0% no-repeat padding-box;
            border-radius: 21px;
            font: normal normal 600 18px/24px $text-font-family;
            letter-spacing: 0px;
            color: $primary-white;
            margin-right: 64px;
          }
        }


        .FontDropdownButtons{
         
          position: absolute;
          top:765px;
          right:51px;
          .cancelButton {
            text-transform: capitalize;
            width: 102px;
            height: 42px;
            background: $wildSand 0% 0% no-repeat padding-box;
            border-radius: 21px;
            font: normal normal 600 18px/24px $text-font-family;
            letter-spacing: 0px;
            color: $labelcolor;
            margin-right: 30px;
          }
          .saveButton {
            text-transform: capitalize;
            width: 156px;
            height: 42px;
            background: $primary-green 0% 0% no-repeat padding-box;
            border-radius: 21px;
            font: normal normal 600 18px/24px $text-font-family;
            letter-spacing: 0px;
            color: $primary-white;
            margin-right: 64px;
          }
        }

       
  
        .buttons{
          margin-left: 64%;
          margin-top: 110px;
          .cancelButton {
            text-transform: capitalize;
            width: 102px;
            height: 42px;
            background: $wildSand 0% 0% no-repeat padding-box;
            border-radius: 21px;
            font: normal normal 600 18px/24px $text-font-family;
            letter-spacing: 0px;
            color: $labelcolor;
            margin-right: 30px;
          }
          .saveButton {
            text-transform: capitalize;
            width: 156px;
            height: 42px;
            background: $primary-green 0% 0% no-repeat padding-box;
            border-radius: 21px;
            font: normal normal 600 18px/24px $text-font-family;
            letter-spacing: 0px;
            color: $primary-white;
            margin-right: 64px;
          }
        }
  
        }


      .dashboard_nav {
        padding-top: 20px;
        padding-bottom: 33px;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        margin-top: 8rem;
        margin-left: 3.75rem;
        margin-right: 3.75rem;
        height: 38px;
        .dashboard_nav_tabs_container {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #e3e3e3;
          .dashboard_tab {
            text-transform: none;
            font-size: $text-lg;
            font-weight: 0;
            color: $primary-gray;
            margin-right: 3rem;
            height: 2.25rem;
            cursor: pointer;
  
            display: flex;
            align-items: center;
            padding-bottom: 0.25rem;
  
            svg.dashboard_tab_material_icon,
            svg.dashboard_tab_Relaxation_icon {
              margin-right: 0.5rem;
            }
            svg.dashboard_tab_defaultTheme_icon,svg.dashboard_tab_customTheme_icon {
              margin-right: 12px;
            }
          }
          .dashboard_tab.active {
            color: $primary-black;
            font-weight: 600;
            border-bottom: 0.25rem solid $primary-green;
            svg.dashboard_tab_material_icon {
              path {
                fill: $primary-black;
              }
            }
            svg.noResult {
              path {
                fill: $primary-black;
              }
            }
            svg.dashboard_tab_Relaxation_icon {
              g {
                path {
                  stroke: $primary-black;
                }
              }
            }
            svg.dashboard_tab_defaultTheme_icon {
              margin-right: 12px;
              g {
                path {
                  stroke: $primary-black;
                }
                circle {
                  stroke: $primary-black;
                }
                line {
                  stroke: $primary-black;
                }
              }
            }
            svg.dashboard_tab_customTheme_icon {
              margin-right: 12px;
              g {
                path {
                  stroke: $primary-black;
                }
              }
            }
          }
  
          .MuiTab-root {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
          }
        }
  
        .dashboard_nav_right {
          display: flex;
          align-items: center;
  
          .searchBox {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            border-left: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
            width: 220px;
            background-color: #f0f4f9;
            position: relative;
            bottom: 2.5rem;
            margin-left: auto;
            svg {
              position: relative;
              left: 10px;
              color: $primary-gray;
            }
  
            .MuiTextField-root {
              .MuiOutlinedInput-root {
                height: 36px;
              }
              height: 36px;
              input {
                text-align: left;
                font: normal normal 400 15px $text-font-family;
                letter-spacing: 0px;
                color: #525252;
              }
              fieldset {
                height: 36px;
                border-style: none;
              }
            }
          }
          .newMaterialBtn {
            text-transform: none;
            background-color: #4a8409;
            text-align: left;
            font-size: 18px;
            font-family: $text-font-family;
            letter-spacing: 0px;
            color: #ffffff;
            border-radius: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            bottom: 3rem;
            margin-left: auto;
            padding: 6px 16px;
          }
        }
      }
    .createButtons{
      margin-left: 64%;
      .createThemeButton {
        width: 182px;
        margin-top: -110px;
        text-transform: capitalize;
        height: 42px;
        background: #4A8409 0% 0% no-repeat padding-box;
        border-radius: 21px;
        font: normal normal 600 18px/24px Segoe UI;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-right: 64px;
      }
      }
    .themeButtons{
      margin-left: 64%;
      margin-top: 45px;
      margin-bottom: 55px;
      .cancelButton {
        text-transform: capitalize;
        width: 102px;
        height: 42px;
        background: #DFE2E8 0% 0% no-repeat padding-box;
        border-radius: 21px;
        font: normal normal 600 18px/24px Segoe UI;
        letter-spacing: 0px;
        color: #3D3D3D;
        margin-right: 30px;
      }
      .applyButton {
        text-transform: capitalize;
        width: 96px;
        height: 42px;
        background: #4A8409 0% 0% no-repeat padding-box;
        border-radius: 21px;
        font: normal normal 600 18px/24px Segoe UI;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-right: 64px;
      }
    }


    .buttonsLogo{
      margin-left: 64%;
      margin-top: 20px;
      .cancelButton {
        text-transform: capitalize;
        width: 102px;
        height: 42px;
        background: #DFE2E8 0% 0% no-repeat padding-box;
        border-radius: 21px;
        font: normal normal 600 18px/24px Segoe UI;
        letter-spacing: 0px;
        color: #3D3D3D;
        margin-right: 30px;
      }
      .saveButton {
        text-transform: capitalize;
        width: 96px;
        height: 42px;
        background: #4A8409 0% 0% no-repeat padding-box;
        border-radius: 21px;
        font: normal normal 600 18px/24px Segoe UI;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-right: 64px;
      }
    }
  }
  .custom_sidebar {
    height: 100%;
    width: 17%;
    float: left;
    background-color: #f7f8fc;
    // position: fixed;
    padding: 1rem 0rem;
    padding-bottom: 360px;
    border: 2px solid #f7f8fc;
    border-right: 1px solid rgb(112, 112, 112, 0.18);
    img {
        width: 138px;
        height: 138px;
        margin-left: 94px;
        margin-top: 45px;
    }
    .adminName {
        font: normal normal normal 46px/62px Segoe UI;
        letter-spacing: 0px;
        color: #3D3D3D;
        opacity: 1;
        margin-left: 69px;
        margin-top: 20px;
    }
    .adminDesignation {
        margin-top: 5px;
        margin-left: 115px;
        margin-bottom: 63px;
        font: normal normal normal 16px/21px Segoe UI;
        letter-spacing: 0px;
        color: #B9B9B9;
    }
    .sidebar_parent_container {
      text-align: left;
      font-size: $text-xl;
      font-weight: 600;
      letter-spacing: 0px;
      color: $gray;
      .sidebar_parent {
        height: 4.125rem;
        text-transform: uppercase;
        font-size: $text-lg;
        color: #767676;
        padding: 1rem 0rem;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        svg {
          margin-right: 1rem;
        }
      }
      .parentActive {
        color: $primary-black !important;
        svg {
          path {
            stroke: $primary-black;
          }
          circle {
            stroke: $primary-black;
          }
        }
      }

      .sidebar_child {
        height: 4.125rem;
        padding: 1rem 0rem;
        display: flex;
        align-items: center;
        font: normal normal normal 20px/27px Segoe UI;
        padding-left: 4.35rem;
        cursor: pointer;
        svg {
          margin-right: 1rem;
        }
      }
      // .sidebar_child.active {
      //   svg {
      //     path {
      //       stroke: #1447d4;
      //       fill: #1447d4;
      //     }
      //     g {
      //       path {
      //         stroke: #1447d4;
      //         fill: #1447d4;
      //       }
      //     }
      //     g {
      //       rect {
      //         // stroke: #1447d4;
      //         // fill: #1447d4;
      //       }
      //     }
      //   }
      // }

      .active {
        background-color: #e6eaf8;
        color: #1447d4;
        border-right: 4px solid $primary-blue;
        font: normal normal 600 20px/27px Segoe UI;
      }
    }
  }
  .theme-Container {
    margin-left: 3.75rem;
    margin-top: 40px;
    p {
      font: normal normal normal 18px/24px Segoe UI;
      letter-spacing: 0px;
      color: #8A8A8A;
    }
    .radioGroup {
      // margin-top: -220px;
      .radioLabel {
        margin-right: 109px;
        margin-bottom: 115px;
      }
      .MuiRadio-colorSecondary.Mui-checked {
        color: #4A8409;
      }
      .MuiRadio-colorSecondary:hover {
          background-color: transparent;
          
      }
    }
    .themeContainer-header {
      background-color: #4A8409;
      color: white;
      padding: 3px;
      padding-left: 14px;
      font-size: 9px;
      .oltivaIcon {
        width: 20px;
        height: 11px;
        margin-left: -9px;
    }
  }
  .themeContainer-appointment {
    box-shadow: 0.1px 1px #e4e5ec;
    background-color: #f7f8fc;
    .heading {
      font-weight: 600;
      font-size: 10px;
      padding-left: 10px;
      margin-top: -2px;
      color: #000000;
    }
    .button {
      margin-top: -28px;
    text-transform: capitalize;
    /* width: 22px; */
    border-radius: 7px;
    background-color: #4A8409;
    height: 5px;
    color: white;
    float: left;
    margin-left: 413px;
    font-size: 5px;
    }
  }
  .themeContainer-sideBar {
    width: 95px;
    padding: 0;
    height: 256px;
    margin-left: 8px;
    margin-top: -9px;
    background-color: #F7F8FC;
    div {
      padding-top: 9px;
      box-shadow: 0.1px 1px #e4e5ec;
      height: 17px;
      text-align: center;
      .searchIcon {
        float: left;
        width: 27%;
        color: #ABB5C4;
        height: 8px;
      }
      .addProviderText {
        font-size: 9px;
        float: left;
        color: #ABB5C4;
        width: 60%;
        margin-top: -9px;
        margin-left: -3px;
      }
      .addIcon {
        border-radius: 3px;
        width: 10%;
        border: solid 1px #0C2E70;
        margin-bottom: 9px;
        height: 9px;
        background-color: #E6EAF8;
        margin-top: -78px;
      }
    }
    .providerList {
      padding-top: 0px;
      box-shadow: none;
      height: 2px;
      .active {
        color: #0C2E70;
        font-weight: 600;
        width: 86px;
        padding-left: 8px;
        margin-left: 0px;
        border: blue;
        background-color: #E6EAF8;
        border-right: #0C2E70;
      }
      p{
        color: #616773;
        font-size: 8px;
        float: left;
        margin-top: -9px;
        margin-left: 14px;
        margin-bottom: 6px;
      }
    }
  }
  .schedule {
    width: 400px;
    height: 20px;
    margin-top: -8px;
    .text {
      font-size: 9px;
      width: 70px;
      font-weight: 600;
      margin-top: -2px;
      color: #000000;
      margin-left: 8px;
    }
    img {
      width: 8px;
      height: 8px;
      position: absolute;
      margin-top: -25px;
      margin-left: 278px;
    }
    .day {
      color: #8A8A8A;
      font-size: 8px;
      margin-top: -33px;
      margin-left: 290px; 
    }
    .frontIcon {
      width: 10px;
      position: absolute;
      margin-top: -32px;
      margin-left: 335px;
      color: #8A8A8A;
    }
    .backIcon {
      width: 10px;
      position: absolute;
      margin-top: -32px;
      margin-left: 320px;
      color: #8A8A8A;
    }
    .date {
      font-size: 8px;
      margin-top: -32px;
      color: #3D3D3D;
      margin-left: 347px;
    }
  }
  .statusTabs {
    width: 400px;
    height: 20px;
    margin-left: 102px;
    margin-top: -238px;
    box-shadow: 0.1px 1px #e4e5ec;
    .tabs {
      font-size: 6px;
      font-weight: 600;
      margin-left: 11px;
      padding: 0px;
      color: #8A8A8A;
      float: left;
      margin-top: -2px;
      margin-right: 0px;
      &.active {
        font-weight: 800;
      }
    }
    .searchBox {
      background-color: #F0F4F9;
      width: 54px;
      padding-left: 4px;
      padding-top: 2px;
      /* padding: 3px; */
      height: 12px;
      margin-top: 2px;
      margin-left: 267px;
      img{
        width: 8px;
        height: 8px;
        float: left;
        padding-top: 2px;
        padding-left: 0px;
      }
      p {
        font-size: 8px;
        margin-top: -7px;
        margin-left: 12px;
      }
    }
    .filter {
      width: 35px;
      margin-left: 83%;
      margin-top: -20px;
      img{
        width: 8px;
        height: 8px;
        float: left ;
      }
      p {
        font-size: 8px;
        color: #000000;
        margin-top: -7px;
        margin-left: 12px;
        margin-top: 0px;
      }
    }
    .icons {
      margin-top: -34px;
      margin-left: 92%;
      .listIcon {
        height: 8px;
        width: 8px;
        margin-right: 2px;
        margin-bottom: 2px;
      }
      .cardIcon {
        height: 12px;
        width: 12px;
        margin-top: 8px;
      }
    }
}
.cardPaperGrid {
  padding-bottom: 0px;
    // padding-left: 14px;
    // margin-top: 2px;
    .cardPaper {
      height: 80px;
      width: 80px;
      margin-bottom: 8px;
      box-shadow: 0.5px 1px 1px 0.5px #e4e5ec;
    }
    .name {
      font-size: 9px;
      font-weight: 600;
      color: #000000;
      padding-top: 2px;
      padding-left: 5px;
  }
  .statusColor {
    width: 54px;
    height: 13px;
    margin-left: 6px;
    margin-top: 3px;
    background-color: #E9F7E5;
    .status{
      font-size: 8px;
      position: absolute;
      margin-left: 3px;
      margin-top: -6px;
    }
  }
  .appointment {
    width: 54px;
    height: 14px;
    margin-left: 6px;
    .apptText {
      color: #8A8A8A;
      font-size: 4px;
      margin-top: -5px;
    }
    .date {
      color: #1E2123;
      font-size: 5px;
      margin-top: -23px;
    }
  }
  .providerDetails {
    width: 54px;
    height: 14px;
    margin-left: 6px;
    .providerText {
      color: #8A8A8A;
      font-size: 4px;
      margin-top: 0px;
    }
    .provider {
      color: #1E2123;
      font-size: 5px;
      margin-top: -23px;
    }
  }
}
  
  }
  .buttons {
    position: absolute;
    margin-left: 435px;
     &:hover {
      cursor:context-menu;
     }
    .edit {
      width: 16px;
      height: 16px;
      color: #BFBFBF; 
      margin-right: 16px;
    }
    .delete {
      width: 12px;
      height: 16px;
    }
  }
  .nameContainer {
    display: flex;
    justify-content: center;
    .themeName {
      font: normal normal normal 18px/24px Segoe UI;
      letter-spacing: 0px;
      color: #3D3D3D;
      position: absolute;
      margin-top: 50px;
    }
  }
}
.SnackBar {
  position: fixed;
  z-index: 12;
  display: flex;
  width: 1925px;
  height: 42px;
  align-items: center;
  color: #18760A;
  font: normal normal normal 18px/24px Segoe UI;
  letter-spacing: 0px;
  padding-left: 20px;
  background-color: #E9F7E5;
  .icon {
    width: 18px;
    height: 18px;
    // margin-top: 1px;
    margin-right: 12px;
  }
  .close {
    color: #2F3543;
    width: 25px;
    height: 25px;
    margin-left: 81%;
    &:hover {
      cursor: pointer;
    }
  }
}

.MuiCardHeader-content {
 span {
  display: block;
  font-size: 18px;
  margin-left: 10px;
 }
}
// .PrivateRadioButtonIcon-root-6 {
//   display: flex;
//   position: relative;
//   margin-top: -225px;
// }

.cursorPtr{
  cursor:pointer
}