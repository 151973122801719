body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-button {
  display: none;
}
.dashboard_header {
  // position: relative;
  position: fixed;
  width: 100%;
  z-index: 12;
  top: 0;
  background-color: $primary-green;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.5rem;
  color: $primary-white;

  .dashboard_header_left {
    display: flex;
    align-items: center;

    .dashboard_header_left_title {
      margin-left: 1rem;
      text-align: left;
      font-size: $text-2xl;
      font-weight: bold;
      letter-spacing: 1.2px;
      color: $primary-white;
      text-transform: uppercase;
    }
  }
  .dashboard_header_right {
    display: flex;
    align-items: center;
    .dashboard_header_right_icon {
      margin-right: 2rem;
      position: relative;
    }
    .notificationIndicator {
      padding: 1px 5px;
      background-color: $error-state;
      color: $primary-white;
      border-radius: 50%;
      font-size: 0.75rem;
      position: absolute;
      top: -5px;
      left: 13px;
    }
    .dashboard_header_right_profile {
      display: flex;
      align-items: center;
      img {
        height: 3rem;
        margin-right: 1rem;
      }
      svg {
        height: 1.5rem;
        margin-top: 0.25rem;
        color: #FFFFFF;
      }
    }
  }
}
.popoverImage {
  margin-left: 25px;
  margin-right: 16px;
}

.MuiPopover-paper {
  width: 138px;
  margin-top: 10px;
}

.dashboard_Container {
  .dashboardContainerContent {
    width: 100%;
    height: 100%;
    position: relative;
    // left: 17%;
    .infoContainer{
      z-index: 1;
      font: normal normal normal $text-base $text-font-family;
      color: $labelcolor;
      background-color: $floral_white;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1.875rem;
      .infoIcon{
      margin-right: 0.75rem;
      }

    }
    .dashboardContainerContent-heading {
      margin-top: 73px;
      background: #f7f8fc 0% 0% no-repeat padding-box;
      text-align: left;
      font-size: $text-2xl;
      font-weight: 600;
      letter-spacing: 0px;
      color: $primary-black;
      padding: 0.75rem 3.75rem;
      border-bottom: 1px solid #dde3ec;
    }

    .dashboard_nav {
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 2rem;
      margin-left: 3.75rem;
      margin-right: 3.75rem;
      border-bottom: 1px solid #e3e3e3;
      height: 38px;
      .dashboard_nav_tabs_container {
        display: flex;
        align-items: center;

        .dashboard_tab {
          text-transform: none;
          font-size: $text-lg;
          font-weight: 0;
          color: $primary-gray;
          margin-right: 3rem;
          height: 2.25rem;
          cursor: pointer;

          display: flex;
          align-items: center;
          padding-bottom: 0.25rem;

          svg.dashboard_tab_material_icon,
          svg.dashboard_tab_Relaxation_icon {
            margin-right: 0.5rem;
          }
        }
        .dashboard_tab.active {
          color: $primary-black;
          font-weight: 600;
          border-bottom: 0.25rem solid $primary-green;
          svg.dashboard_tab_material_icon {
            path {
              fill: $primary-black;
            }
          }
          svg.noResult {
            path {
              fill: $primary-black;
            }
          }
          svg.dashboard_tab_Relaxation_icon {
            g {
              path {
                stroke: $primary-black;
              }
            }
          }
          svg.dashboard_tab_systemSettings_icon {
            g {
              path {
                stroke: $primary-black;
              }
              circle {
                stroke: $primary-black;
              }
              line {
                stroke: $primary-black;
              }
            }
          }
          svg.dashboard_tab_meetingSettings_icon {
            g {
              path {
                stroke: $primary-black;
              }
            }
          }
        }

        .MuiTab-root {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }
      }

      .dashboard_nav_right {
        display: flex;
        align-items: center;

        .searchBox {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
          border-left: 1px solid #e6e6e6;
          border-right: 1px solid #e6e6e6;
          width: 220px;
          background-color: #f0f4f9;
          position: relative;
          bottom: 2.5rem;
          margin-left: auto;
          svg {
            position: relative;
            left: 10px;
            color: $primary-gray;
          }

          .MuiTextField-root {
            .MuiOutlinedInput-root {
              height: 36px;
            }
            height: 36px;
            input {
              text-align: left;
              font: normal normal 400 15px $text-font-family;
              letter-spacing: 0px;
              color: #525252;
            }
            fieldset {
              height: 36px;
              border-style: none;
            }
          }
        }
        .newMaterialBtn {
          text-transform: none;
          background-color: #4a8409;
          text-align: left;
          font-size: 18px;
          font-family: $text-font-family;
          letter-spacing: 0px;
          color: #ffffff;
          border-radius: 21px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          bottom: 3rem;
          margin-left: auto;
          padding: 6px 16px;
        }
      }
    }
  }
}

.errorPopup{
  .MuiPopover-paper {
    width: 564px;
  }
}

.errorPopupContainer{
  padding:20px;
  .basicDet{
    font: normal normal 600 $text-md $text-font-family;
    letter-spacing: 0px;
    color: $primary-blue;
    margin-bottom: 8px;
    margin-top: 8px
  }
  .errDet{
    border-bottom: 1px solid $separator;
    padding-bottom: 16px;
  }
  .dFlex{
    display: flex;
    .mL{
     margin-left:16px;
    }
    .mR{
      margin-right:16px;
          }
    .viewDeat{
      margin-left: auto;
      font: normal normal normal $text-base $text-font-family;
      color: $blueTitle;
      cursor:pointer;
    }
    .errorName{
      font: normal normal normal $text-base $text-font-family;
    }
  }

  .errorPopupHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    p.errorPopupText {
     
      margin-block-start: 0px !important;
      margin-block-end: 0px !important;
      font: normal normal 600 $text-xl $text-font-family;
      color: $persianRed;
    }

    svg {
      cursor: pointer;
    }
  }
  

  .inboxContent {
    padding: 10px;
    border: 1px solid $separator;
    border-radius: 4px;
    width: 500px;
    height: auto;
    margin-bottom: 15px;

    section:first-child {
      margin-bottom: 5px;
      text-align: left;
      font: normal normal 600 $text-sm $text-font-family;
      letter-spacing: 0px;
      color: $labelcolor;
      opacity: 1;

      span {
        margin-right: 10px;
      }
    }

    section:nth-child(2) {
      font: normal normal normal $text-base $text-font-family;
      letter-spacing: 0px;
      color: $labelcolor;
      opacity: 1;
    }
  }
  }

.noResultStyle {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-left: 3.75rem;
  font-size: $text-lg;

  svg {
    margin-right: 0.5rem;
  }
}

.noResultStyleNoUser {
  font-size: $text-xl;
  margin-top: 11rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  font-weight: 500;
  svg {
    margin-right: 0.5rem;
  }
}

.dashboard_spinner {
  position: absolute;
  right: 50%;
}

.pointer {
  cursor: pointer;
}

.errorMessage {
  font-size: $text-base;
  text-align: left;
  color: red;
}

.infoAlert {
  margin-left: 3.75rem;
  margin-top: 1rem;
  background-color: #e8f4fd !important;
  min-height: 2.625rem;
  width: 90%;
  padding: 0rem 1rem !important;
  .MuiAlert-icon {
    svg {
      height: 1.5rem;
      path {
        color: $primary-black;
      }
    }
  }
  .MuiAlert-message {
    color: $primary-black;
    font-size: $text-base;
    font-weight: 600;
  }
}
